import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'

import PageContent from '../old/pages/About'
import '../assets/styles.css'

export default ({ data }) => {
  return (
    <React.Fragment>
      <SEO title={'About Us'} />
      <Header
        title={'About Us'}
        lightOnDark={true}
        barStyle={'overlay'}
        showCallToActionButton={true}
        background={{ image: data.hero.edges[0].node.childImageSharp.fluid }}
      />
      <PageContent />
      <Footer />
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    hero: allFile(filter: { relativePath: { eq: "hero-about.jpg" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
