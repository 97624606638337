import React from 'react'
import classNames from 'classnames'

import Page from '../templates/Page'
import PageSection from '../templates/PageSection'
import Contact from '../components/Contact'
import { RowLayout } from '../../components/layout'

import './about.css'

class About extends React.Component {
  render() {
    return (
      <Page
        className={classNames('about')}
        headerTitle="About Us"
        withHeaderBackgroundImage
        header={null}
      >
        <PageSection
          className={classNames('our-story', 'with-background-image')}
        >
          <div className={classNames('column', 'padded-section-content')}>
            <h1 className={classNames('section-title')}>Our Story</h1>

            <RowLayout isResponsive className={classNames('row')}>
              <div className={classNames('column')}>
                <h3>Where We Have Been</h3>
                <p>
                  Live365, a true pioneer, started webcasting in 1999 when it
                  was revolutionary to let people stream and listen to internet
                  based audio. The talent and passion of its webcasters quickly
                  brought a global audience and explosive growth. Live365 grew
                  to support tens of thousands of diverse Internet radio
                  broadcasters by offering a one-stop-shop of tools, music
                  licensing, and distribution. Unfortunately, due to changes in
                  U.S. licensing laws, Live365 closed on January 31st of 2016.
                  It was a sad day for Internet radio and the many passionate
                  programmers and listeners who had poured their heart into
                  Live365.
                </p>
                <h3>Onward</h3>
                <p>
                  With new owners, a renewed passion for webcasters, and added
                  experience, Live365 relaunched on May 01, 2017. The ‘new’
                  Live365 is even more dedicated and passionate about giving
                  small webcasters the best place to stream on the internet.
                  With powerful streaming tools, monetization and listening
                  distribution, Live365 is again the best one-stop-shop for your
                  internet radio station.
                </p>
              </div>
            </RowLayout>
          </div>
        </PageSection>

        <Contact
          className={classNames('page-section')}
          description="Join our team and write history."
        />
      </Page>
    )
  }
}

export default About
